<template>
  <div class="payments">
    <v-row>
      <v-col md="12">
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-expand-transition>
      <PendingPlanChangeAlert />
    </v-expand-transition>
    <payments-table
      :payments-url="'customer/payments'"
      :show-add-payment-dialog="showAddPaymentDialog"
      @close-payment-dialog="showAddPaymentDialog = false"
    />
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import Breadcrumb from '@/components/partials/BreadCrumb.vue'
import PaymentsTable from '@/components/tables/PaymentsTable.vue'
import PendingPlanChangeAlert from '@/components/partials/PendingPlanChangeAlert.vue'

export default {
  components: {
    Breadcrumb,
    PaymentsTable,
    PendingPlanChangeAlert,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        { text: 'Payments', disabled: true },
      ],
      icons: {
        mdiPlus,
      },
      showAddPaymentDialog: false,
    }
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
}
</script>
