var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payments-table"},[_c('v-card',[(_vm.userId)?_c('v-card-title',{staticClass:"mb-0 pb-1"},[_vm._v(" Payment Details "),_c('v-spacer')],1):_vm._e(),_c('v-card-title',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-md-3"},[_c('refresh-button',{attrs:{"loading":_vm.isPaymentsRefreshing},on:{"click":_vm.refreshPayments}})],1),_c('v-col',{staticClass:"col-md-6 text-md-right"},[_c('table-filter',{attrs:{"table-name":'payments',"show-date-filter":true,"show-payment-method-filter":true,"filters":_vm.tableFilters,"default-date-picker-date":_vm.defaultDatePickerDate},on:{"filter":_vm.applyFilters,"filterLabelChange":_vm.onFilterLabelChange}})],1),_c('v-col',{staticClass:"col-md-3 text-md-right"},[_c('export-button',{attrs:{"title":_vm.exportTitle,"headers":_vm.exportHeaders,"data-endpoint":_vm.constructCompletePaymentsUrl,"data-path":'payments',"orientation":'landscape'}})],1)],1),_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-md-6 text-center"},[_c('search-input',{attrs:{"filters":_vm.filteredSearchFilters,"active-filter":_vm.searchColumn},on:{"filter-change":_vm.onSearchFilterChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.payments,"loading":_vm.isPaymentsLoading,"server-items-length":_vm.pagination.totalItems,"options":_vm.options,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mpesa_payment ? item.mpesa_payment.first_name : item.customer.user.name)+" ")]}},{key:"item.account_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.account_number)+" ")]}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mpesa_payment ? item.mpesa_payment.msisdn : item.customer.user.phone_number)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" Ksh "+_vm._s(_vm._f("formatCurrency")(item.amount))+" ")]}},{key:"item.station",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.station.name)+" ")]}},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getChipColor(item.payment_method)}},[_vm._v(" "+_vm._s(item.payment_method)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","loading":_vm.viewingPaymentReceiptsLoading[item.id],"disabled":_vm.viewingPaymentReceiptsLoading[item.id]},on:{"click":function($event){$event.stopPropagation();return _vm.viewPaymentReceipt(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._b({},'v-icon',attrs,false),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View receipt pdf")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-3":"","text":"","icon":"","loading":_vm.loadingPaymentReceipts[item.id],"disabled":_vm.loadingPaymentReceipts[item.id]},on:{"click":function($event){$event.stopPropagation();return _vm.downloadPaymentReceipt(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download receipt pdf")])])]}}])}),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }